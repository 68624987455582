import * as React from "react"
import { graphql } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export const homeFragment = graphql`
  fragment HomeSeoFragment on Contentstack_home_2_0 {
    homeSeo: seo_og_meta_data {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`

export const mediaFragment = graphql`
  fragment MediaSeoFragment on Contentstack_forge_2_0_media_page {
    mediaSeo: seo_meta {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`

export const aboutFragment = graphql`
  fragment AboutSeoFragment on Contentstack_forge_about_page {
    aboutSeo: page_meta_data {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`
export interface SeoData {
  pageTitle: string
  pageDescription: string
  siteImage: {
    url: string
  }
  url: string
  siteName: string
}

interface Props {
  data: SeoData
  url: string
  locale: string
}

const Seo: React.FC<Props> = ({ data, url, locale }) => {
  const {
    pageTitle,
    pageDescription,
    siteImage,
    siteName,
  } = data;

  const ogLocale = locale.replace(/(-[a-z]{2,})/, v => v.replace("-", "_").toUpperCase())

  return (
    <GatsbySeo
      title={pageTitle}
      description={pageDescription}
      canonical={url}
      openGraph={{
        url: url,
        title: pageTitle,
        description: pageDescription,
        images: [{
          url: siteImage.url,
        }],
        site_name: siteName,
        locale: ogLocale,
      }}
    />
  )
}

export default Seo

