import React, { Children } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types';

interface BTN {
  type: "primary" | "secondary" | "tertiary"| "quaternary" | "quinary";
  size: "small" | "medium" | "large";
}

type BTN_TYPE = {
  type: string;
  size: string;
};

const Module = styled.button<BTN_TYPE>`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  box-sizing: border-box;

  padding: 0;
  margin: 0;
  border: none;
  outline: none;

  span{
    color: #ffffff;
    transform: translate3d(-4px, -4px, 0);
    transition: transform 240ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
    transition-delay: 220ms;
  }

  &:active, &.click{
    span{
      transform: translate3d(0, 0, 0);
      transition: transform 120ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
    }
  } 

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    span{
    padding: ${(props) =>
      props.size === "small"
        ? "10px 10px"
        : props.size === "medium"
        ? "10px 10px"
        : props.size === "large"
        ? "10px 10px"
        : null};
        
    font-size: ${(props) =>
      props.size === "small" ? "14px" : props.size === "medium" ? "14px" : props.size === "large" ? "14px" : null};
  
    line-height: ${(props) =>
      props.size === "small" ? "24px" : props.size === "medium" ? "24px" : props.size === "large" ? "24px" : null};
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    span{
    padding: ${(props) =>
      props.size === "small"
        ? "4px 21px"
        : props.size === "medium"
        ? "12px 28px"
        : props.size === "large"
        ? "12px 28px"
        : null};

    font-size: ${(props) =>
      props.size === "small" ? "14px" : props.size === "medium" ? "16px" : props.size === "large" ? "16px" : null};

    line-height: ${(props) =>
      props.size === "small" ? "24px" : props.size === "medium" ? "16px" : props.size === "large" ? "16px" : null};
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    span{
    padding: ${(props) =>
      props.size === "small"
        ? "4px 10px"
        : props.size === "medium"
        ? "12px 28px"
        : props.size === "large"
        ? "12px 36px"
        : null};
        
    font-size: ${(props) =>
      props.size === "small" ? "14px" : props.size === "medium" ? "16px" : props.size === "large" ? "20px" : null};
  
    line-height: ${(props) =>
      props.size === "small" ? "24px" : props.size === "medium" ? "16px" : props.size === "large" ? "32px" : null};
      }
    };
`;

const PrimaryButton = styled(Module)`
  background: transparent;
  
  span {
    background: #30353f;
    border: 4px solid #111111;
  }

  &:hover {
    span {
      background: #4a5160;
    }
  }
  &:active {
    span {
      background: #676a72;
    }
  }
  &:disabled {
    background: #676a72;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SecondaryButton = styled(Module)`
  background: #111111;
  span {
    background: #ffffff;
    border: 4px solid #111111;
    color: #30353f;
  }

  &:hover {
    background: #8b8682;
    span {
      border: 4px solid #8b8682;
    }
  }
  &:active {
    background: #111111;
    span {
      background: #f5f5f5;
      border: 4px solid #111111;
    }
  }
  &:disabled {
    background: #ffffff;
    opacity: 0.5;
    border: 4px solid #111111;
    box-shadow: 4px 4px 0px #111111;
    cursor: not-allowed;
  }
`;

const TertiaryButton = styled(Module)`
  background: #ffffff;
  span {
    background: #30353f;
    border: 4px solid #ffffff;
  }

  &:hover {
    span {
      background: #4a5160;
    }
  }
  &:active {
    span {
      background: #676a72;
    }
  }
  &:disabled {
    background: #676a72;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const QuaternaryButton = styled(Module)`
  background: #111111;
  span {
    background: #ffffff;
    border: 4px solid #111111;
  }
  p {
    color: #111111;
  }

  &:hover {
    span {
      background: #4a5160;
      border: 4px solid #111111;
    }
    p {
      color: white;
    }
  }
  &:active {
    span {
      background: #676a72;
    }
  }
  &:disabled {
    background: #676a72;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const QuinaryButton = styled(Module)`
  background: #111111;
  span {
    background: #31353E;
    border: 4px solid #111111;
  }
  p {
    color: #ffffff;
  }

  &:hover {
    span {
      background: #4a5160;
      border: 4px solid #111111;
    }
    p {
      color: white;
    }
  }
  &:active {
    span {
      background: #676a72;
    }
  }
  &:disabled {
    background: #676a72;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type Props = {
  onClick?: () => void;
  className?: string;
  href?: string;
  as?: string;
  children: React.ReactNode;
  type: "primary" | "secondary" | "tertiary" | "quaternary" | "quinary";
  size: "small" | "medium" | "large";
  target?:"_blank";
};

function Button(props: Props) {
  let Component: any;

  if (props.type === "primary") {
    Component = PrimaryButton;
  } else if (props.type === "secondary") {
    Component = SecondaryButton;
  } else if (props.type === "tertiary") {
    Component = TertiaryButton;
  } else if (props.type === "quaternary") {
    Component = QuaternaryButton;
  } else if (props.type === "quinary") {
    Component = QuinaryButton;
  } else {
    throw new Error(`Unrecognized Button variant: ${props.type}`);
  }

  return (
    <Component {...props}>
      <span>{Children.toArray(props.children)}</span>
    </Component>
  );
}

export default Button;
