/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react";
// @ts-ignore
import lax from "lax.js";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby"

import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";

import { ThemeProvider, StyleSheetManager, createGlobalStyle } from "styled-components";
import rtlcss from "stylis-rtlcss";

import Header from "./Header";
import Footer from "./Footer";

import { normalize } from "./normalize";
import { Helmet } from "react-helmet";

import { ScreenBadge } from "react-awesome-styled-grid";

import "../styles/index.css";

const customConf = {
  mediaQuery: "only screen",
  columns: {
    xs: 2,
    sm: 2, //mobile 320 - 768
    md: 8, //Tablet 768 - 1440
    lg: 12, //Laptop 1440 - 2560
    xl: 12, //Desktop min 2560
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 1.25,
    lg: 1.25,
    xl: 1.25,
  },
  paddingWidth: {
    // container sağ/sol padding
    xs: 1.25,
    sm: 1.25,
    md: 1.25,
    lg: 1.25,
    xl: 1.25,
  },
  container: {
    xs: 17, //  max-width: 272px
    sm: 39.25, // max-width: 628px
    md: 76.875, // max-width: 1230px
    lg: 85.875, // max-width: 1230px
    xl: 85.875, // max-width: 1230px
  },
  breakpoints: {
    xs: 1,
    sm: 20, // 320px
    md: 48, // 768px
    lg: 62.5, // 1440px
    xl: 160, // 2560p
  },
};

export const variables = {
  primary: {
    white: "#FFFFFF",
    black: "#111111",
  },
  secondary: {
    smoke: "#30353F",
    base: "#8B8682",
    mushroom: "#D8D1C8",
    cream: "#F1EDE5",
    grey: "#4A4F58",
    grey400: "#C4C4C4",
  },
  error: {
    500: "#EF4444",
  },
  spacing: {
    xsmall: "0.25rem",
    small: "0.5rem",
    normal: "1rem",
    large: "1.5rem",
    xlarge: "2rem",
  },
  icon: {
    xsmall: "0.5rem",
    small: "1rem",
    normal: "1.5rem",
    large: "2rem",
  },
};

const GlobalStyle = createGlobalStyle`${normalize}`;

type Props = {
  children: any;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { language } = useI18next();

  useEffect(() => {
    lax.init();

    lax.addDriver("scrollY", function () {
      return window.scrollY;
    });

    // Add your elements
    lax.addElements(".animation-hero", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY", "elOutY"],
          ["elHeight", 0, "-elHeight"],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 0.5],
        ],
      },
    });
    lax.addElements(".animation-opacity", {
      scrollY: {
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    });
    lax.addElements(".animation-left", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          ["-elWidth", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    });
    lax.addElements(".animation-right", {
      scrollY: {
        translateX: [
          ["elInY", "elCenterY", "elOutY"],
          ["elWidth", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY", "elOutY"],
          [0, 1, 1],
        ],
      },
    });
    lax.addElements(".animation-up", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY-200", "elOutY"],
          ["elHeight/2", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY-100", "elOutY"],
          [0.5, 1, 1],
        ],
      },
    });
    lax.addElements(".animation-up-gallery", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY-elHeight/2", "elOutY"],
          ["elHeight/2", 0, 0],
        ],
        opacity: [
          ["elInY", "elCenterY-elHeight/2", "elOutY"],
          [0.5, 1, 1],
        ],
      },
    });
    lax.addElements(".animation-height", {
      scrollY: {
        translateY: [
          ["elInY", "elCenterY", "elOutY"],
          ["elHeight", 0, 0],
        ],
      },
    });

    return () => {
      lax.removeDriver("scrollY");
    };
  }, [children]);
  //rtlcss
  return (
    <>
      <ThemeProvider theme={{ awesomegrid: customConf, variables }}>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/iaw7mae.css" />
        </Helmet>
        <GlobalStyle />
        <StyleSheetManager stylisPlugins={[]}>
          <>
            {/* <Header /> */}
            <RiotBar product="riotforge" locale={language} />
            <main>{children}</main>
            {/* <Footer /> */}
            {/* <ScreenBadge /> */}
          </>
        </StyleSheetManager>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
