import { graphql, useStaticQuery } from "gatsby";

/**
 * Add types for your siteMetadata here.
 */
export interface SiteMetadata {
  title: string;
  author: string;
  description: string;
  buildEnv: string;
  siteDomain: string;
}

/**
 * React hook to easily use siteMetadata from gatsby-config.
 *
 * Usage:
 *
 * const { title } = useSiteMetadata();
 *
 * Used in any component it will return static metadata that will be used at
 * build-time.
 */
export const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            buildEnv
            siteDomain
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};
